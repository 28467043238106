import React, { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import IconButton from '@/components/icon/IconButton';
import MoreItem, { MoreItemProp } from './MoreItem';
import useDevice from '@/hooks/useDevice';
import useDropdown from '@/hooks/useDropdown';
import useEsc from '@/hooks/useEsc';
import { debounce, genUid, getPosition } from '@/utils/helper';
import { IconSize, IconType } from '@/components/icon/Icon';

import styles from './styles.module.scss';
import Button from '../form/button/Button';

const cx = classNames.bind(styles);

interface Props {
    title?: string;
    ariaLabel: string;
    buttonLabel: string;
    children: ReactNode;
}

const More: React.FC<Props> = ({ title, ariaLabel, buttonLabel, children }) => {
    const { isMobile } = useDevice();

    const [allChildrenHidden, areAllChildrenHidden] = useState(false);
    const [calculating, isCalculating] = useState(true);
    const [maxWidth, setMaxWidth] = useState(0);
    const [showMore, isMoreVisible] = useState(true);
    const [menuItems, setMenuItems] = useState<MoreItemProp[]>([]);
    const [position, setPosition] = useState<
        { x: number; y: number } | undefined
    >();
    const [showMenu, setMenuOpen] = useState(false);

    const parentElem = useRef<HTMLElement | null>();
    const containerElem = useRef<HTMLDivElement>(null);
    const childrenElem = useRef<HTMLDivElement>(null);
    const menuElem = useRef<HTMLDivElement>(null);
    const triggerElem = useRef<HTMLDivElement>(null);

    const {
        open: moreMenuOpen,
        toggleDropdown,
        closeDropdown,
    } = useDropdown({
        triggerRef: triggerElem.current,
        dropdownRef: menuElem.current,
    });

    const calculateMaxWidth = () => {
        const container = containerElem.current;

        if (container) {
            parentElem.current = container.parentElement;

            if (parentElem?.current) {
                const parentWidth = parentElem.current.offsetWidth;

                setMaxWidth(parentWidth);
            }
        }
    };

    useEsc({ escHandler: closeDropdown });

    useEffect(() => {
        if (moreMenuOpen && triggerElem.current && !isMobile) {
            const pos = getPosition(triggerElem.current);
            const menuWidth = menuElem.current?.offsetWidth;

            if (menuWidth) {
                pos.x =
                    pos.x - (menuWidth - triggerElem.current.offsetWidth) - 2;
                pos.y = pos.y + triggerElem.current.offsetHeight;
                setPosition(pos);
            }
        }

        window.setTimeout(() => {
            setMenuOpen(moreMenuOpen);
        }, 100);
    }, [isMobile, moreMenuOpen]);

    useEffect(() => {
        let totalWidth = 0;
        const children = childrenElem.current?.childNodes;
        const menuItemList: MoreItemProp[] = [];

        if (children) {
            const totalChildren = children.length;

            children?.forEach((c: HTMLElement) => {
                c.style.display = 'block';
                c.classList.remove('--truncate');
            });

            for (let c = 0; c < totalChildren; c++) {
                const child = children[c] as HTMLElement;
                const prevChildIdx = c - 1;
                const prevChild = children[prevChildIdx] as HTMLElement;
                const link = child.querySelector('a');
                totalWidth += child.offsetWidth + 2;

                if (link && totalChildren > 1) {
                    const href = link.getAttribute('href');
                    const label = link.textContent;

                    if (label && href) menuItemList.push({ label, href });

                    if (totalWidth > maxWidth) {
                        child.style.display = 'none';

                        if (prevChild && prevChildIdx) {
                            prevChild.style.display = 'none';
                        }
                    }
                }
            }

            if (totalWidth > maxWidth) {
                isMoreVisible(true);
            } else {
                isMoreVisible(false);
            }

            setMenuItems(menuItemList);
        }
    }, [maxWidth]);

    useEffect(() => {
        const children = childrenElem.current?.childNodes;
        let hiddenCount = 0;

        if (children) {
            children.forEach((c: HTMLElement) => {
                if (c.style.display === 'none') hiddenCount++;
            });

            if (hiddenCount === children.length) {
                areAllChildrenHidden(true);
            } else {
                areAllChildrenHidden(false);
            }
        }
    }, [menuItems]);

    useEffect(() => {
        const viewportListener = debounce(calculateMaxWidth, 200, null);

        if (typeof window !== 'undefined') {
            document.addEventListener('scroll', viewportListener);
            window.addEventListener('resize', viewportListener);
            window.addEventListener('orientationchange', viewportListener);

            calculateMaxWidth();

            window.setTimeout(() => {
                isCalculating(false);
            }, 500);
        }

        return () => {
            if (typeof window !== 'undefined') {
                document.addEventListener('scroll', viewportListener);
                window.removeEventListener('resize', viewportListener);
                window.removeEventListener(
                    'orientationchange',
                    viewportListener
                );
            }
        };
    }, [children]);

    return (
        <div
            ref={containerElem}
            className={cx(
                'more',
                { '--open': moreMenuOpen },
                { '--calculating': calculating }
            )}
            style={{ width: `${maxWidth}px` }}>
            <div className={cx('__list')}>
                <div ref={childrenElem} className={cx('__children')}>
                    {children}
                </div>
                {showMore && (
                    <div ref={triggerElem} className={cx('__trigger')}>
                        <IconButton
                            ariaLabel={ariaLabel}
                            type={
                                !moreMenuOpen
                                    ? IconType.ChevronDown
                                    : IconType.Close
                            }
                            theme='white'
                            size={IconSize.Small}
                            onPress={toggleDropdown}
                        />
                        <div
                            ref={menuElem}
                            className={cx('__menu', { '--open': showMenu })}
                            style={{
                                top: position?.y,
                                left: position?.x,
                            }}>
                            <div className={cx('__menu-title')}>{title}</div>
                            <div className={cx('__menu-container')}>
                                {menuItems.map((m) => (
                                    <MoreItem
                                        key={`${genUid()}`}
                                        label={m.label}
                                        href={m.href}
                                    />
                                ))}
                            </div>
                            <button
                                type='button'
                                className={cx('__more-menu-item', '__close')}
                                onClick={() => closeDropdown()}>
                                <span className={cx('__label')}>Close</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(More);
