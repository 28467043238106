import { useState } from 'react';

interface Props {
    triggerRef?: HTMLElement | null;
    dropdownRef?: HTMLElement | null;
    openDropdownHandler?: () => void;
    closeDropdownHandler?: () => void;
}

const useDropdown = ({
    triggerRef,
    dropdownRef,
    openDropdownHandler,
    closeDropdownHandler,
}: Props) => {
    const [open, isOpen] = useState(false);

    const toggle = () => {
        const visible = !open;
        isOpen(visible);

        if (visible && typeof window !== 'undefined') {
            window.setTimeout(() => {
                window.removeEventListener('click', closeListener);
                window.removeEventListener('keyup', closeListener);
                window.removeEventListener('scroll', closeListener);

                window.addEventListener('click', closeListener);
                window.addEventListener('keyup', closeListener);
                window.addEventListener('scroll', closeListener);
            }, 100);
        }

        openDropdownHandler?.();
    };

    const clickOutsideHandler = (evt: Event) => {
        const target = evt?.target as HTMLElement;
        const trigger = triggerRef;
        const dropdown = dropdownRef;

        if (typeof window !== 'undefined' && trigger && dropdown) {
            if (!trigger.contains(target) && !dropdown.contains(target))
                return true;
        }
    };

    const closeListener = (evt: Event) => {
        if (clickOutsideHandler(evt)) {
            window.removeEventListener('click', closeListener);
            window.removeEventListener('keyup', closeListener);
            document.removeEventListener('scroll', closeListener);

            (document.activeElement as HTMLElement).blur();

            isOpen(false);
            closeDropdownHandler?.();
        }
    };

    const close = () => {
        window.removeEventListener('click', closeListener);
        window.removeEventListener('keyup', closeListener);
        document.removeEventListener('scroll', closeListener);

        (document.activeElement as HTMLElement).blur();

        isOpen(false);
        closeDropdownHandler?.();
    };

    return {
        open,
        toggleDropdown: toggle,
        closeDropdown: close,
    };
};

export default useDropdown;
