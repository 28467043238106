import classNames from 'classnames/bind';

import Spinner from '@/components/loading/spinner/Spinner';
import Icon, { IconType } from '@/components/icon/Icon';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

export interface PillData {
    value?: string | object | number | null;
    slug?: string;
}

interface Props {
    label: string;
    theme?: ElementTheme;
    value?: string | object | number;
    slug?: string;
    size?: 'tiny' | 'small' | 'default' | 'large';
    onPress?: (data: PillData) => void;
    href?: string;
    active?: boolean;
    busy?: boolean;
    icon?: IconType;
    showRightArrow?: boolean;
    showDownArrow?: boolean;
    disabled?: boolean;
}

const Pill = ({
    label,
    theme = 'primary',
    value,
    slug,
    size = 'default',
    onPress,
    href,
    active,
    busy,
    icon,
    showRightArrow,
    showDownArrow,
    disabled,
}: Props) => {
    const onPressHandler = (data: PillData) => {
        if (!active) onPress?.(data);
    };

    return (
        <div className={cx('pill-container')}>
            {onPress && (
                <button
                    type='button'
                    className={cx(
                        'pill',
                        `pill--${theme}`,
                        `pill--${size}`,
                        { 'pill--active': active },
                        { 'pill--busy': busy },
                        { 'pill--disabled': disabled },
                        { 'pill--has-right-arrow': showRightArrow },
                        { 'pill--interactive': onPress },
                        'pill--button'
                    )}
                    onClick={() => onPressHandler({ value, slug })}
                    disabled={disabled || busy}>
                    {icon ? <Icon name={icon} /> : null}
                    <span className={cx('pill__label')}>{label}</span>
                    {showRightArrow ? (
                        <Icon name={IconType.ChevronRight} />
                    ) : null}
                    {showDownArrow ? (
                        <Icon name={IconType.ChevronDown} />
                    ) : null}
                </button>
            )}
            {href && (
                <a
                    className={cx(
                        'pill',
                        `pill--${theme}`,
                        `pill--${size}`,
                        { 'pill--active': active },
                        { 'pill--busy': busy },
                        { 'pill--disabled': disabled },
                        { 'pill--has-right-arrow': showRightArrow },
                        { 'pill--interactive': onPress },
                        'pill--button'
                    )}
                    href={href}>
                    {icon ? <Icon name={icon} /> : null}
                    <span className={cx('pill__label')}>{label}</span>
                    {showRightArrow ? (
                        <Icon name={IconType.ChevronRight} />
                    ) : null}
                    {showDownArrow ? (
                        <Icon name={IconType.ChevronDown} />
                    ) : null}
                </a>
            )}
            {!onPress && !href && (
                <div
                    className={cx(
                        'pill',
                        `pill--${theme}`,
                        `pill--${size}`,
                        { 'pill--active': active },
                        { 'pill--busy': busy },
                        { 'pill--disabled': disabled },
                        { 'pill--has-right-arrow': showRightArrow }
                    )}>
                    {icon ? <Icon name={icon} /> : null}
                    <span className={cx('pill__label')}>{label}</span>
                    {showRightArrow ? (
                        <Icon name={IconType.ChevronRight} />
                    ) : null}
                    {showDownArrow ? (
                        <Icon name={IconType.ChevronDown} />
                    ) : null}
                </div>
            )}
            {busy ? <Spinner type='primary' /> : null}
        </div>
    );
};

export default Pill;
