import React from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

export interface MoreItemProp {
    label: string;
    href: string;
}

const MoreItem: React.FC<MoreItemProp> = ({ label, href }) => {
    const router = useRouter();

    return (
        <button
            type='button'
            onClick={() => router.push(href)}
            className={cx('__more-menu-item')}>
            <span className={cx('__label')}>{label}</span>
        </button>
    );
};

export default React.memo(MoreItem);
